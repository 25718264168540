import React, { useEffect, useState } from "react"
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import { DataService } from "./services/data-service";
import { IUserStore } from "./redux/user/types";
import { useDispatch } from "react-redux";
import { LoginUser } from "./redux/user/userReducer";
import { Protected } from "./Protected";
// import LoginPage from "./auth-pages/Login";
import { getUserToken } from "./helpers/user-token";
import { CircularProgress } from "@mui/material";
import Loader from "./components/Loader";
import BaseLayout from "./layouts/BaseLayout";
import LoginPage from "./pages/auth-pages/Login";

interface IUserDetailsResponse {
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    points: number;
    pointsValue: number;
    role: number;
    status: number;
}

export const Portal: React.FC = () => {

    const [loaded, setLoaded] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        DataService.post("api/user/details", {}).then(async (res) => {
            if (res.status === 200) {
                const result: IUserDetailsResponse = await res.json();
                var newStore: IUserStore = {
                    token: getUserToken() || '',
                    active: result.status == 1,
                    role: result.role.toString(),
                    isAuthenticated: true,
                    user: {
                        firstName: result.firstName,
                        lastName: result.lastName,
                        email: result.email,
                        id: result.id,
                        role: result.role,
                        status: result.status
                    }
                };
                dispatch(LoginUser(newStore));
                setLoaded(true);
            } else {
                // (window.location as any).href = "/login";
                setLoaded(true);

            }
        }).catch(() => {
            // debugger;
            // (window.location as any).href = "/login";
            setLoaded(true);
        });
    }, []);

    return loaded ? <Routes>
        <Route path="login" element={<BaseLayout children={<LoginPage />} />} />
        <Route path="*" element={<Protected />} />
    </Routes> : <Loader size={75} />
}
import { isEmpty } from "./validation-utils";

export function cloneObjectBasic<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
}

export function ensurePlusPrefix(mobileNumber: string): string {
    if(isEmpty(mobileNumber)){
        return mobileNumber;
    }
    
    if (!mobileNumber.startsWith("+")) {
      return "+" + mobileNumber;
    }
    return mobileNumber;
  }

  export function GetToday(){
    var date = new Date();

    date.setHours(0, 0, 0, 0);
    return date;
  }


  export function getDate(date: string | Date){
    var newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }
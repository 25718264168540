import React, { useEffect, useState } from 'react';
import EnhancedTable from 'src/components/enhanced-table/EnhancedTable';
import {
  EnhanceTableHeaderTypes,
  EnhancedTableColumnType,
  ITableMenuAction
} from 'src/components/enhanced-table';
import { useAppDispatch } from 'src/redux/hooks';
import { IConfirmation } from 'src/redux/confirmation/types';
import { OpenConfirmation } from 'src/redux/confirmation/confirmationReducer';
import { Helmet } from 'react-helmet-async';
import MainPageTitle from 'src/components/layout-components/main-layout/MainPageTitle';
import { useNavigate } from 'react-router';
import { DataService } from 'src/services/data-service';
import Loader from 'src/components/Loader';
import { ensurePlusPrefix } from 'src/helpers/utils';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';
import {
  ITableFilterType,
  TableFilterTypes
} from 'src/components/enhanced-table/index-filter';
import AddBrand from './add-brands';
import EditBrand from './edit-brand';

interface IBrandResponseItem {
  id: string;
  title: string;
  description: string;
  logo: string;
  active: boolean;
}

interface IBrandListing {
  id: string;
  title: string;
  description: string;
  logo: string;
  active: boolean;
  status: string;
}

const BrandListing: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Record<string, IBrandListing>>();
  const [openAddBrand, setOpenAddBrand] = useState<boolean>(false);
  const [updateData, setUpdateData] = useState<IBrandListing>();
  const [openEditBrand, setOpenEditBrand] = useState<boolean>(false);

  const activate = (id: string) => {
    const confimation: IConfirmation = {
      open: true,
      title: 'Confirmation',
      message: 'Do you want to activate this Brand batch?',
      onSubmit: async () => {
        const result = await DataService.post('/api/Brand/activate/' + id, {});
        if (result.ok) {
          initilize();
          toast.success('Success!');
        } else {
          toast.error('An error occured');
        }
      }
    };

    dispatch(OpenConfirmation(confimation));
  };
  const edit = (id: string) => {
    setUpdateData(data[id]);
    setOpenEditBrand(true);
  };
  const deactivate = (id: string) => {
    const confimation: IConfirmation = {
      open: true,
      title: 'Confirmation',
      message: 'Do you want to deactivate this Brand batch?',
      onSubmit: async () => {
        const result = await DataService.post(
          '/api/Brand/deactivate/' + id,
          {}
        );
        if (result.ok) {
          initilize();
          toast.success('Success!');
        } else {
          toast.error('An error occured');
        }
      }
    };

    dispatch(OpenConfirmation(confimation));
  };

  const download = async (id: string) => {
    if (data[id].logo) {
      window.open(data[id].logo, '_blank');
    }
  };

  const headers: EnhanceTableHeaderTypes[] = [
    {
      id: 'title',
      label: 'Name',
      type: EnhancedTableColumnType.Clickable,
      onClick: (id, row) => {
        navigate('/brands/' + row.id);
      }
    },
    {
      id: 'description',
      label: 'Description',
      type: EnhancedTableColumnType.TEXT
    },

    {
      id: 'status',
      label: 'Status',
      type: EnhancedTableColumnType.COLORED_CHIP,
      chipColors: {
        Active: { backgroundColor: 'green', color: 'white' },
        Inactive: { backgroundColor: 'red', color: 'white' }
      }
    },

    {
      id: 'actions',
      label: 'Actions',
      type: EnhancedTableColumnType.Action,
      actions: [
        {
          onClick: (id) => {
            activate(id);
          },
          icon: <CheckIcon fontSize="small" />,
          label: 'Activate',
          hidden: (r) => r.status?.toLowerCase() === 'active'
        },
        {
          onClick: (id) => {
            deactivate(id);
          },
          icon: <CancelIcon fontSize="small" />,
          label: 'Deactivate',
          hidden: (r) => r.status?.toLowerCase() === 'inactive'
        },
        {
          onClick: (id) => {
            edit(id);
          },
          icon: <EditIcon fontSize="small" />,
          label: 'Edit',
          hidden: (r) => false
        },
        {
          onClick: (id) => {
            download(id);
          },
          icon: <CloudDownloadIcon fontSize="small" />,
          label: 'Download',
          hidden: (r) => false
        }
      ]
    }
  ];

  const tableFiler: ITableFilterType[] = [
    // {
    //   type: TableFilterTypes.SELECT,
    //   name: 'status',
    //   title: 'Status',
    //   defaultValue: ['Active']
    // }
  ];

  const initilize = async () => {
    setLoading(true);
    const response = await DataService.get('/api/Brand/all');

    if (response.ok) {
      const list: { result: IBrandResponseItem[] } = await response.json();
      const result: Record<string, IBrandListing> = {};
      list.result.forEach((c) => {
        result[c.id] = {
          id: c.id,
          title: c.title,
          description: c.description,
          logo: c.logo,
          active: c.active,
          status: c.active ? 'Active' : 'Inactive'
        };
      });
      setData(result);
      setLoading(false);
    }
  };

  useEffect(() => {
    initilize();
  }, []);

  const actions: ITableMenuAction[] = [
    // {
    //     key: "addcontact",
    //     title: "Add Contact",
    //     disabled: (selected: string[]) => selected.length > 0,
    //     onClick: () => {
    //         setAddContact(true)
    //     },
    // },
    // {
    //     key: "editcontact",
    //     title: "Edit Contact",
    //     disabled: (selected: string[]) => selected.length === 0,
    //     onClick: (selected) => {
    //         const confirm: IConfirmation = {
    //             open: true,
    //             title: "Hi",
    //             message: "Are you sure?",
    //             onSubmit: () => { console.log("hi") }
    //         }
    //         dispatch(OpenConfirmation(confirm));
    //     }
    // }
  ];

  if (!data || loading) {
    return <Loader size={75} />;
  }

  return (
    <div>
      <Helmet>
        <title>Brands</title>
      </Helmet>
      <MainPageTitle
        title={'Brand Listing'}
        action={{
          title: 'Add',
          onClick: () => {
            // navigate("/users/add")
            if (!openAddBrand) {
              setOpenAddBrand(true);
            }
          }
        }}
      />

      {/* <MainPageTitle title="Customers" /> */}

      <EnhancedTable
        title="Brands"
        defaultOrder=""
        header={headers}
        data={data}
        filters={tableFiler}
        actions={actions}
      />

      {openAddBrand && (
        <AddBrand
          onSuccess={function (): void {
            toast.success('Success!');
            setOpenAddBrand(false);
            initilize();
          }}
          onClose={function (): void {
            setOpenAddBrand(false);
          }}
        />
      )}

      {openEditBrand && (
        <EditBrand
          onSuccess={function (): void {
            toast.success('Success!');
            setOpenEditBrand(false);
            initilize();
          }}
          onClose={function (): void {
            setOpenEditBrand(false);
          }}
          updateData={updateData}
        />
      )}
    </div>
  );
};

export default BrandListing;

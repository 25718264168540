import { toast } from "react-toastify"
import { DynamicField, DynamicFieldTypes } from "src/components/dynamic-widget"
import DynamicFormWidget from "src/components/dynamic-widget/DynamicFormWidget"
import { isValidEmail } from "src/helpers/validation-utils"
import { DataService } from "src/services/data-service"
import { userRolesList } from "."
import { useState } from "react"
import { useNavigate } from "react-router"

const AddUser = () => {

    const navigate = useNavigate();
    const [formConfig, setFormConfig] = useState<Record<string, DynamicFieldTypes>>({
        firstName: {
            type: DynamicField.TEXT,
            required: true,
            name: "firstName",
            title: "First Name",
            value: "",
            disabled: false
        },
        lastName: {
            type: DynamicField.TEXT,
            required: true,
            name: "lastName",
            title: "Last Name",
            value: "",
            disabled: false
        },
        email: {
            type: DynamicField.TEXT,
            required: true,
            name: "email",
            title: "Email",
            value: "",
            disabled: false,
            inputType: "email",
            customValidator: (v: string) => { return isValidEmail(v) ? "" : "Invalid Email Address" }
        },
        role: {
            type: DynamicField.SELECT,
            required: true,
            name: "role",
            title: "Role",
            value: "",
            disabled: false,
            items: userRolesList
        }
    });

    const submit = async (data: Record<string, any>) => {
        const response = await DataService.post(
            "api/User/register",
            {...data, role: Number(data.role), country: "Lebanon"},
            undefined,
        );

        if (response.ok) {
            toast.success("Success");
            // To reset form
            setFormConfig({...formConfig});
            navigate("/users");
            return true;
        } else {
            const result: { message: string } = await response.json();
            toast.error(result.message || "An error occurred!");
            return false;
        }
    };


    return <DynamicFormWidget title="Add User" fields={formConfig} onSubmit={async (v) => { return await submit(v) }} />
}
export default AddUser;
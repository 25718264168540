export const setUserToken = (token: string): void => {
    localStorage.setItem('jwtToken', token);
};

export const getUserToken = (): string | null => {
    return localStorage.getItem('jwtToken');
};

export const clearUserToken = (): void => {
    localStorage.removeItem('jwtToken');
};
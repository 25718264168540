import React, { useEffect, useState } from 'react';
import EnhancedTable from 'src/components/enhanced-table/EnhancedTable';
import {
  EnhanceTableHeaderTypes,
  EnhancedTableColumnType,
  ITableMenuAction
} from 'src/components/enhanced-table';
import { useAppDispatch } from 'src/redux/hooks';
import { IConfirmation } from 'src/redux/confirmation/types';
import { OpenConfirmation } from 'src/redux/confirmation/confirmationReducer';
import { Helmet } from 'react-helmet-async';
import MainPageTitle from 'src/components/layout-components/main-layout/MainPageTitle';
import { useNavigate } from 'react-router';
import { DataService } from 'src/services/data-service';
import Loader from 'src/components/Loader';
import { ensurePlusPrefix } from 'src/helpers/utils';
import { toast } from 'react-toastify';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import AddDiscount from './add-discount';
import EditIcon from '@mui/icons-material/Edit';
import {
  ITableFilterType,
  TableFilterTypes
} from 'src/components/enhanced-table/index-filter';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import UpdateDiscount from './update-discount';

interface IDiscountListing {
  id: string;
  name: string;
  note: string;
  startDate: string;
  expiryDate: string;
  file: string;
  status: string;
}

const DiscountListing: React.FC = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Record<string, IDiscountListing>>();
  const [updateData, setUpdateData] = useState<IDiscountListing>();

  const [openAddDiscount, setOpenAddDiscount] = useState<boolean>(false);
  const [openEditDiscount, setOpenEditDiscount] = useState<boolean>(false);

  const activate = (id: string) => {
    const confimation: IConfirmation = {
      open: true,
      title: 'Confirmation',
      message: 'Do you want to activate this discount batch?',
      onSubmit: async () => {
        const result = await DataService.post(
          'api/discount/activate/' + id,
          {}
        );
        if (result.ok) {
          initilize();
          toast.success('Success!');
        } else {
          toast.error('An error occured');
        }
      }
    };

    dispatch(OpenConfirmation(confimation));
  };

  const edit = (id: string) => {
    setUpdateData(data[id]);
    setOpenEditDiscount(true);
  };

  const deactivate = (id: string) => {
    const confimation: IConfirmation = {
      open: true,
      title: 'Confirmation',
      message: 'Do you want to deactivate this discount batch?',
      onSubmit: async () => {
        const result = await DataService.post(
          '/api/discount/deactivate/' + id,
          {}
        );
        if (result.ok) {
          initilize();
          toast.success('Success!');
        } else {
          toast.error('An error occured');
        }
      }
    };

    dispatch(OpenConfirmation(confimation));
  };

  const download = async (id: string) => {
    if(data[id].file){
      window.open(data[id].file, "_blank");
    }
  };

  const headers: EnhanceTableHeaderTypes[] = [
    { id: 'name', label: 'Name', type: EnhancedTableColumnType.TEXT },
    { id: 'note', label: 'Note', type: EnhancedTableColumnType.TEXT },
    {
      id: 'startDate',
      label: 'Start Date',
      type: EnhancedTableColumnType.DATE
    },
    {
      id: 'expiryDate',
      label: 'Expiry Date',
      type: EnhancedTableColumnType.DATE
    },
    {
      id: 'status',
      label: 'Status',
      type: EnhancedTableColumnType.COLORED_CHIP,
      chipColors: {
        Active: { backgroundColor: 'green', color: 'white' },
        Inactive: { backgroundColor: 'red', color: 'white' }
      }
    },
    {
      id: 'actions',
      label: 'Actions',
      type: EnhancedTableColumnType.Action,
      actions: [
        {
          onClick: (id) => {
            activate(id);
          },
          icon: <CheckIcon fontSize="small" />,
          label: 'Activate',
          hidden: (r) => r.status?.toLowerCase() === 'active'
        },
        {
          onClick: (id) => {
            deactivate(id);
          },
          icon: <CancelIcon fontSize="small" />,
          label: 'Deactivate',
          hidden: (r) => r.status?.toLowerCase() === 'inactive'
        },
        {
          onClick: (id) => {
            edit(id);
          },
          icon: <EditIcon fontSize="small" />,
          label: 'Edit',
          hidden: (r) => false
        },
        {
          onClick: (id) => {
            download(id);
          },
          icon: <CloudDownloadIcon fontSize="small" />,
          label: 'Download',
          hidden: (r) => false
        }
      ]
    }
  ];

  const tableFiler: ITableFilterType[] = [
    {
      type: TableFilterTypes.SELECT,
      name: 'status',
      title: 'Status',
      defaultValue: ['Active']
    }
  ];

  const initilize = async () => {
    setLoading(true);
    const response = await DataService.get('api/Discount/all');
    if (response.ok) {
      const list: { result: any[] } = await response.json();
      const result: Record<string, IDiscountListing> = {};
      list.result.forEach((c) => {
        result[c.id] = {
          id: c.id,
          name: c.name,
          note: c.note,
          startDate: c.startDate,
          expiryDate: c.expiryDate,
          status: c.active ? 'Active' : 'Inactive',
          file: c.fileUrl
        };
      });

      setData(result);
      setLoading(false);
    }
  };

  useEffect(() => {
    initilize();
  }, []);

  const actions: ITableMenuAction[] = [
    // {
    //     key: "addcontact",
    //     title: "Add Contact",
    //     disabled: (selected: string[]) => selected.length > 0,
    //     onClick: () => {
    //         setAddContact(true)
    //     },
    // },
    // {
    //     key: "editcontact",
    //     title: "Edit Contact",
    //     disabled: (selected: string[]) => selected.length === 0,
    //     onClick: (selected) => {
    //         const confirm: IConfirmation = {
    //             open: true,
    //             title: "Hi",
    //             message: "Are you sure?",
    //             onSubmit: () => { console.log("hi") }
    //         }
    //         dispatch(OpenConfirmation(confirm));
    //     }
    // }
  ];

  if (!data || loading) {
    return <Loader size={75} />;
  }

  return (
    <div>
      <Helmet>
        <title>Discounts</title>
      </Helmet>
      <MainPageTitle
        title={'Discount Listing'}
        action={{
          title: 'Add',
          onClick: () => {
            // navigate("/users/add")
            if (!openAddDiscount) {
              setOpenAddDiscount(true);
            }
          }
        }}
      />

      {/* <MainPageTitle title="Customers" /> */}

      <EnhancedTable
        title="Discounts"
        header={headers}
        data={data}
        filters={tableFiler}
        actions={actions}
      />

      {openAddDiscount && (
        <AddDiscount
          onSuccess={function (): void {
            toast.success('Success!');
            setOpenAddDiscount(false);
            initilize();
          }}
          onClose={function (): void {
            setOpenAddDiscount(false);
          }}
        />
      )}
      {openEditDiscount && (
        <UpdateDiscount
          onSuccess={function (): void {
            toast.success('Success!');
            setOpenEditDiscount(false);
            initilize();
          }}
          onClose={function (): void {
            setOpenEditDiscount(false);
          }}
          updateData={updateData}
        />
      )}
    </div>
  );
};

export default DiscountListing;

import { Modal } from '@mui/material';
import { DynamicField, DynamicFieldTypes } from 'src/components/dynamic-widget';
import DynamicFormWidget from 'src/components/dynamic-widget/DynamicFormWidget';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDate } from 'src/helpers/formatting-utils';
import { FileType } from 'src/helpers/file-utils';
import GenericDrawer from 'src/components/drawer/GenericDrawer';
import { GetToday } from 'src/helpers/utils';
import { useEffect } from 'react';
interface IBrandItemListing {
  id: string;
  text: string;
  type: string;
}
interface IUpdateBrandProps {
  onSuccess: () => void;
  onClose: () => void;
  updateData: IBrandItemListing;
}
const items = { video: 'Video', text: 'Text', image: 'Image', link: 'Link' };

const EditBrandItem: React.FC<IUpdateBrandProps> = ({
  onSuccess,
  onClose,
  updateData
}) => {
  const { id } = useParams();

  const formConfig: Record<string, DynamicFieldTypes> = {
    title: {
      type: DynamicField.TEXT,
      required: true,
      name: 'title',
      title: 'Title',
      value: updateData.text,
      disabled: false
    },
    type: {
      type: DynamicField.SELECT,
      required: true,
      name: 'type',
      title: 'Type',
      items: items,
      value: updateData.type,
      disabled: false
    },

    file: {
      type: DynamicField.FILE,
      allowedTypes: [FileType.PNG, FileType.JPEG, FileType.JPG],
      required: false,
      name: 'file',
      title: 'Brand File',
      value: '',
      disabled: false
    }
  };
  useEffect(() => {
    console.log(updateData.type);
  }, [updateData]);

  const submit = async (data: Record<string, any>) => {
    const file: File = data.file;

    let formValue = {
      text: data.text,
      type: data.type,
      id: data.id
    };
    const formData = new FormData();
    formData.append('request', JSON.stringify(formValue));

    if (file) {
      formData.append('formFile', file, file.name);
    }

    const req = await DataService.postForm(
      '/api/BrandItem/update-item/' + id,
      formData
    );
    if (req.ok) {
      await onSuccess();
      return true;
    } else {
      toast.error('An Error occured!');
    }
    return false;
  };

  return (
    <GenericDrawer title={'Update Item'} open={true} onClose={onClose}>
      <DynamicFormWidget
        drawerMode
        // title="Add Discount"
        title=""
        fields={formConfig}
        onSubmit={async (e) => {
          return await submit(e);
        }}
      />
    </GenericDrawer>
  );
};

export default EditBrandItem;

export enum InformationWidgetFieldTypes {
    Text = "Text",
    Currency = "Curreny",
    Date = "Date",
    Datetime = "Datetime",
    Boolean = "Boolean"
}

export interface IInformationWidgetField {
    type: InformationWidgetFieldTypes;
    name: string;
    title: string;
    description?: string;
    width: "third" | 'two-third' | 'full'
}

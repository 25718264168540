import React, { useEffect, useState } from 'react';
import EnhancedTable from 'src/components/enhanced-table/EnhancedTable';
import { EnhanceTableHeaderTypes, EnhancedTableColumnType, ITableMenuAction } from 'src/components/enhanced-table';
import { useAppDispatch } from 'src/redux/hooks';
import { IConfirmation } from 'src/redux/confirmation/types';
import { OpenConfirmation } from 'src/redux/confirmation/confirmationReducer';
// import EditCustomer from './edit-customer';
import { Helmet } from 'react-helmet-async';
import MainPageTitle from 'src/components/layout-components/main-layout/MainPageTitle';
import { useNavigate } from 'react-router';
import { DataService } from 'src/services/data-service';
// import { CustomerStatus, ICustomerDetails } from '.';
import Loader from 'src/components/Loader';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import PreviewIcon from '@mui/icons-material/Preview';
import { getStoreById } from 'src/utils/store-utils';
import { toast } from 'react-toastify';
import { TableFilterTypes } from 'src/components/enhanced-table/index-filter';

interface ITransactionListModel {
    id: string;
    createdOn: string;
    customerId: string;
    customerName: string;
    extendedData: string | null;
    pointsGained: number;
    pointsUsed: number;
    storeId: string;
    total: number;
    storeName: string;
    country: string;
}

const TransactionListing: React.FC = () => {

    // const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<Record<string, ITransactionListModel>>();


    const headers: EnhanceTableHeaderTypes[] = [
        { id: 'customerName', label: 'Customer Name', type: EnhancedTableColumnType.Clickable, onClick: (id, row) => { navigate("/customers/" + row.customerId) } },
        { id: 'total', label: 'Total', type: EnhancedTableColumnType.CURRENCY },
        { id: 'pointsGained', label: 'Points Gained', type: EnhancedTableColumnType.NUMBER },
        { id: 'pointsUsed', label: 'Points Used', type: EnhancedTableColumnType.NUMBER },
        { id: 'storeName', label: 'Store', type: EnhancedTableColumnType.TEXT },
        { id: 'country', label: 'Country', type: EnhancedTableColumnType.TEXT },
        { id: 'createdOn', label: 'Created On', type: EnhancedTableColumnType.DATETIME },
    ];


    const initilize = async () => {
        const response = await DataService.get("/api/Transaction/all-detailed");
        if (response.ok) {
            const list: ITransactionListModel[] = await response.json();
            const result: Record<string, ITransactionListModel> = {};

            list.forEach(c => {
                const store = getStoreById(c.storeId);
                result[c.id] = {...c, storeName: store?.StoreName || '--', country: store?.Country || '--'};
            });

            setData(result);
        } else {
            toast.error("An error occurred");
        }
    }

    useEffect(() => {
        initilize();
    }, []);


    const actions: ITableMenuAction[] = [
        // {
        //     key: "addcontact",
        //     title: "Add Contact",
        //     disabled: (selected: string[]) => selected.length > 0,
        //     onClick: () => {
        //         setAddContact(true)
        //     },
        // },
        // {
        //     key: "editcontact",
        //     title: "Edit Contact",
        //     disabled: (selected: string[]) => selected.length === 0,
        //     onClick: (selected) => {

        //         const confirm: IConfirmation = {
        //             open: true,
        //             title: "Hi",
        //             message: "Are you sure?",
        //             onSubmit: () => { console.log("hi") }
        //         }

        //         dispatch(OpenConfirmation(confirm));
        //     }
        // }
    ];

    if (!data) {
        return <Loader size={75} />
    }

    return (
        <div>
            <Helmet>
                <title>Transactions List</title>
            </Helmet>
            <MainPageTitle title={"Transactions List"} 
            // action={{
            //     title: "Add Customer",
            //     onClick: () => {
            //         navigate("/customers/add")
            //     },
            // }} 
            />

            {/* <MainPageTitle title="Customers" /> */}

            <EnhancedTable
                title="Transactions"
                header={headers}
                data={data}
                actions={actions}
                filters={[
                    {
                        name: "createdOn",
                        title: "Created On",
                        type: TableFilterTypes.DATERANGE
                    }
                ]}
            />

            {/* {
                addContact && <AddCustomer onClose={() => {
                    setAddContact(false)
                }} onSuccess={function (): void {
                    throw new Error('Function not implemented.');
                }} />
            } */}

            {/* {
                editContact && <EditCustomer onClose={() => {
                    setEditContact(false)
                }} onSuccess={function (): void {
                    throw new Error('Function not implemented.');
                }} />
            } */}

        </div>
    );
};

export default TransactionListing;

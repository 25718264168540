import { Modal } from '@mui/material';
import { DynamicField, DynamicFieldTypes } from 'src/components/dynamic-widget';
import DynamicFormWidget from 'src/components/dynamic-widget/DynamicFormWidget';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FileType } from 'src/helpers/file-utils';
import GenericDrawer from 'src/components/drawer/GenericDrawer';
import { useEffect, useRef, useState } from 'react';

interface IAddDiscountProps {
  onSuccess: () => void;
  onClose: () => void;
}

const AddOffer: React.FC<IAddDiscountProps> = ({ onSuccess, onClose }) => {
  const navigate = useNavigate();
  const [validToDate, setValidToDate] = useState<any>(new Date());
  const formConfig: Record<string, DynamicFieldTypes> = {
    title: {
      type: DynamicField.TEXT,
      required: true,
      name: 'title',
      title: 'Title',
      value: '',
      disabled: false
    },
    content: {
      type: DynamicField.TEXTAREA,
      required: true,
      name: 'content',
      title: 'Content',
      value: '',
      disabled: false
    },
    validFrom: {
      type: DynamicField.DATE,
      min: new Date(),
      required: true,
      name: 'validFrom',
      title: 'Valid From',
      value: '',
      disabled: false
    },
    validTo: {
      type: DynamicField.DATE,
      required: true,
      min: validToDate,
      name: 'validTo',
      title: 'Valid To',
      value: '',
      disabled: false
    },
    active: {
      type: DynamicField.SELECT,
      required: true,
      name: 'active',
      title: 'Active',
      value: '',
      disabled: false,
      items: { yes: 'Yes', no: 'No' }
    },
    file: {
      type: DynamicField.FILE,
      allowedTypes: [FileType.PNG, FileType.JPEG, FileType.JPG],
      required: true,
      maxSizeInMbs: 5,
      name: 'file',
      title: 'Offer File',
      value: '',
      disabled: false
    }
  };

  const submit = async (data) => {
    let data2 = {
      title: data.title,
      validFrom: data.validFrom,
      content: data.content,
      validTo: data.validTo,
      active: data.active === 'yes',
      featured: false
    };
    if (data.validFrom > data.validTo) {
      toast.error('Invalid Date');
    }
    const formData = new FormData();
    const file: File = data.file;
    formData.append('request', JSON.stringify(data2));
    formData.append('formFile', file, file.name);
    const req = await DataService.postForm('/api/Offer/create', formData);
    if (req.ok) {
      await onSuccess();
      return true;
    } else {
      toast.error('An Error occured!');
    }
    return false;
  };

  return (
    <>
      <GenericDrawer title={'Add Offer'} open={true} onClose={onClose}>
        <DynamicFormWidget
          title=""
          drawerMode
          fields={formConfig}
          onSubmit={async (e) => {
            return await submit(e);
          }}
        />
      </GenericDrawer>
    </>
  );
};

export default AddOffer;

import { Modal } from '@mui/material';
import { DynamicField, DynamicFieldTypes } from 'src/components/dynamic-widget';
import DynamicFormWidget from 'src/components/dynamic-widget/DynamicFormWidget';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FileType } from 'src/helpers/file-utils';
import GenericDrawer from 'src/components/drawer/GenericDrawer';

interface IAddDiscountProps {
  onSuccess: () => void;
  onClose: () => void;
}

const AddOffer: React.FC<IAddDiscountProps> = ({ onSuccess, onClose }) => {
  const navigate = useNavigate();

  const formConfig: Record<string, DynamicFieldTypes> = {
    title: {
      type: DynamicField.TEXT,
      required: true,
      name: 'title',
      title: 'Title',
      value: '',
      disabled: false
    },
    description: {
      type: DynamicField.TEXTAREA,
      required: true,
      name: 'description',
      title: 'Body',
      value: '',
      disabled: false
    }

    /*file: {
      type: DynamicField.FILE,
      allowedTypes: [FileType.PNG, FileType.JPEG, FileType.JPG],
      required: true,
      maxSizeInMbs: 5,
      name: 'file',
      title: 'Offer File',
      value: '',
      disabled: false
    }*/
  };

  const submit = async (data) => {
    let data2 = {
      title: data.title,
      description: data.description
    };
    const formData = new FormData();
    //  const file: File = data.file;
    // formData.append('formFile', file, file.name);

    const req = await DataService.post('api/Notification/push', data2);

    if (req.ok) {
      await onSuccess();
      return true;
    } else {
      toast.error('An Error occured!');
    }
    return false;
  };

  return (
    <>
      <GenericDrawer title={'Add Notification'} open={true} onClose={onClose}>
        <DynamicFormWidget
          title=""
          drawerMode
          fields={formConfig}
          onSubmit={async (e) => {
            return await submit(e);
          }}
        />
      </GenericDrawer>
    </>
  );
};

export default AddOffer;

export interface IStoreDetails {
  StoreCode: string;
  StoreName: string;
  Location: string;
  Country: string;
  Icon: string;
}


const stores: IStoreDetails[] = [
    {
      "StoreCode": "CD01",
      "StoreName": "LCWaikiki Matrix Mall",
      "Location": "Gombe, Kinshasa",
      "Country": "Democratic Republic of the Congo",
      "Icon": "https://meckengeneral.blob.core.windows.net/cdn/store-icons/lc-w-icon.jpg"
    },
    {
      "StoreCode": "CD800",
      "StoreName": "LCWaikiki Home",
      "Location": "Gombe, Kinshasa",
      "Country": "Democratic Republic of the Congo",
      "Icon": "https://meckengeneral.blob.core.windows.net/cdn/store-icons/lc-w-icon.jpg"
    },
    {
      "StoreCode": "DS01",
      "StoreName": "Department Store Matrix Mall",
      "Location": "Gombe, Kinshasa",
      "Country": "Democratic Republic of the Congo",
      "Icon": "https://meckengeneral.blob.core.windows.net/cdn/store-icons/lc-w-icon.jpg"
    },
    {
      "StoreCode": "FLO01",
      "StoreName": "FLO Store Matrix Tower",
      "Location": "Gombe, Kinshasa",
      "Country": "Democratic Republic of the Congo",
      "Icon": "https://meckengeneral.blob.core.windows.net/cdn/store-icons/lc-w-icon.jpg"
    },
    {
      "StoreCode": "CD800",
      "StoreName": "LCWaikiki Home",
      "Location": "Gombe, Kinshasa",
      "Country": "Democratic Republic of the Congo",
      "Icon": "https://meckengeneral.blob.core.windows.net/cdn/store-icons/lc-w-icon.jpg"
    },
    {
      "StoreCode": "IN01",
      "StoreName": "Instreet",
      "Location": "Gombe, Kinshasa",
      "Country": "Democratic Republic of the Congo",
      "Icon": "https://meckengeneral.blob.core.windows.net/cdn/store-icons/lc-w-icon.jpg"
    },
    {
      "StoreCode": "CD02",
      "StoreName": "LCWaikiki",
      "Location": "Lubumbashi",
      "Country": "Democratic Republic of the Congo",
      "Icon": "https://meckengeneral.blob.core.windows.net/cdn/store-icons/lc-w-icon.jpg"
    },
    {
      "StoreCode": "DS02",
      "StoreName": "Department Store",
      "Location": "Lubumbashi",
      "Country": "Democratic Republic of the Congo",
      "Icon": "https://meckengeneral.blob.core.windows.net/cdn/store-icons/lc-w-icon.jpg"
    },
    {
      "StoreCode": "CD03",
      "StoreName": "LCWaikiki",
      "Location": "Limitee",
      "Country": "Democratic Republic of the Congo",
      "Icon": "https://meckengeneral.blob.core.windows.net/cdn/store-icons/lc-w-icon.jpg"
    },
    {
      "StoreCode": "CG01",
      "StoreName": "LCWaikiki",
      "Location": "BrazzaVille",
      "Country": "Republic of the Congo",
      "Icon": "https://meckengeneral.blob.core.windows.net/cdn/store-icons/lc-w-icon.jpg"
    },
    {
      "StoreCode": "USPACG01",
      "StoreName": "USPOLO",
      "Location": "BrazzaVille",
      "Country": "Republic of the Congo",
      "Icon": "https://meckengeneral.blob.core.windows.net/cdn/store-icons/lc-w-icon.jpg"
    },
    {
      "StoreCode": "CG02",
      "StoreName": "LCWaikiki",
      "Location": "Pointe Moire",
      "Country": "Republic of the Congo",
      "Icon": "https://meckengeneral.blob.core.windows.net/cdn/store-icons/lc-w-icon.jpg"
    }
  ];

  export function getStoreById(id: string) {
    if (!id) {
      return null;
    }
  
    const idLowerCase = id.toLowerCase();
    return stores.find(store => store.StoreCode.toLowerCase() === idLowerCase);
  }
import React, { useEffect, useState } from 'react';
import EnhancedTable from 'src/components/enhanced-table/EnhancedTable';
import {
  EnhanceTableHeaderTypes,
  EnhancedTableColumnType,
  ITableMenuAction
} from 'src/components/enhanced-table';
import { useAppDispatch } from 'src/redux/hooks';
import { IConfirmation } from 'src/redux/confirmation/types';
import { OpenConfirmation } from 'src/redux/confirmation/confirmationReducer';
import { Helmet } from 'react-helmet-async';
import MainPageTitle from 'src/components/layout-components/main-layout/MainPageTitle';
import { useNavigate } from 'react-router';
import { DataService } from 'src/services/data-service';
import Loader from 'src/components/Loader';
import { ensurePlusPrefix } from 'src/helpers/utils';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';
import {
  ITableFilterType,
  TableFilterTypes
} from 'src/components/enhanced-table/index-filter';
import AddNotification from './add-notifications';

interface INotificationResponseItem {
  body: string;
  createdOn: string;
  id: string;
  image: string;
  title: string;
}

interface INotificationListing {
  id: string;
  title: string;
  createdOn: string;
  body: string;
  image: string;
}

const NotificationListing: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Record<string, INotificationListing>>();
  const [openAddNotification, setOpenAddNotification] =
    useState<boolean>(false);

  const download = async (id: string) => {
    if (data[id].image) {
      window.open(data[id].image, '_blank');
    }
  };

  const headers: EnhanceTableHeaderTypes[] = [
    { id: 'title', label: 'Name', type: EnhancedTableColumnType.TEXT },
    {
      id: 'body',
      label: 'Body',
      type: EnhancedTableColumnType.TEXT
    },

    {
      id: 'createdOn',
      label: 'Created On',
      type: EnhancedTableColumnType.DATE
    },
    {
      id: 'actions',
      label: 'Actions',
      type: EnhancedTableColumnType.Action,
      actions: [
        {
          onClick: (id) => {
            download(id);
          },
          icon: <CloudDownloadIcon fontSize="small" />,
          label: 'Download',
          hidden: (r) => false
        }
      ]
    }
  ];

  const tableFiler: ITableFilterType[] = [

  ];

  const initilize = async () => {
    3;
    setLoading(true);
    const response = await DataService.get('/api/Notification/all');

    if (response.ok) {
      const list: INotificationResponseItem[] = await response.json();
      const result: Record<string, INotificationListing> = {};
      list.forEach((c) => {
        result[c.id] = {
          id: c.id,
          title: c.title,
          body: c.body,
          createdOn: c.createdOn,
          image: c.image
        };
      });

      setData(result);
      setLoading(false);
    }
  };

  useEffect(() => {
    initilize();
  }, []);

  if (!data || loading) {
    return <Loader size={75} />;
  }

  return (
    <div>
      <Helmet>
        <title>Notifications</title>
      </Helmet>
      <MainPageTitle
        title={'Notification Listing'}
        action={{
          title: 'Add',
          onClick: () => {
            if (!openAddNotification) {
              setOpenAddNotification(true);
            }
          }
        }}
      />

      {/* <MainPageTitle title="Customers" /> */}

      <EnhancedTable
        title="Notifications"
        defaultOrder=""
        header={headers}
        data={data}
        filters={tableFiler}
      />

      {openAddNotification && (
        <AddNotification
          onSuccess={function (): void {
            toast.success('Success!');
            setOpenAddNotification(false);
            initilize();
          }}
          onClose={function (): void {
            setOpenAddNotification(false);
          }}
        />
      )}
    </div>
  );
};

export default NotificationListing;

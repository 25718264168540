import React from 'react';
import GenericInputWrapper from './GenericInputWrapper';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { makeStyles } from 'tss-react/mui';
import { TextField } from '@mui/material';

interface IGenericDateRangePickerProps {
    title: string;
    value: [Date | undefined, Date | undefined];
    onChange: (value: [Date | undefined, Date | undefined]) => void;
    error?: string;
    name: string;
    onBlur?: (name: string) => void;
    disabled?: boolean;
}

const useStyles = makeStyles<{ error: boolean }>()(
    (_theme, { error }) => ({
        input: {
            width: '100%',
        },
        errorInput: {},
    })
);

const GenericDateRangePicker: React.FC<IGenericDateRangePickerProps> = ({
    title,
    value,
    disabled,
    error,
    onChange,
    name,
    onBlur = () => {},
}) => {
    const { classes } = useStyles({ error: !!error });

    return (
        <GenericInputWrapper title={title} error={error} name={name}>
            <DateRangePicker
                onChange={onChange}
                value={value}
                className={classes.input}
                disabled={disabled}
                clearIcon={null} // Optional: if you don't want to show the clear icon
                // Additional props as needed, for example, to customize styles
            />
        </GenericInputWrapper>
    );
};

export default GenericDateRangePicker;

import React from 'react';
import { makeStyles } from 'tss-react/mui';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles()({
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        position: 'fixed',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        top: 0,
        left: 0,
        zIndex: 9999,
    }
});

const Loader: React.FC<{size?: number}> = ({size = 70}) => {
    const { classes } = useStyles();
    return (
        <div className={classes.loaderContainer}>
            <CircularProgress size={size} color="success" style={{color: "#243043 !important"}} />
        </div>
    );
};

export default Loader;

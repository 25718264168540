import React, { useEffect, useState } from 'react';
import EnhancedTable from 'src/components/enhanced-table/EnhancedTable';
import { EnhanceTableHeaderTypes, EnhancedTableColumnType, ITableMenuAction } from 'src/components/enhanced-table';
import { useAppDispatch } from 'src/redux/hooks';
import { IConfirmation } from 'src/redux/confirmation/types';
import { OpenConfirmation } from 'src/redux/confirmation/confirmationReducer';
import { Helmet } from 'react-helmet-async';
import MainPageTitle from 'src/components/layout-components/main-layout/MainPageTitle';
import { useNavigate } from 'react-router';
import { DataService } from 'src/services/data-service';
import { UserStatus, IUserDetails } from '.';
import Loader from 'src/components/Loader';
import { ensurePlusPrefix } from 'src/helpers/utils';
import { toast } from 'react-toastify';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';


interface IUserListing {
    id: string;
    fullName: string;
    mobileNumber: string;
    email: string;
    status: string;
    country: string;
    role: string;
}


const UserListing: React.FC = () => {

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<Record<string, IUserListing>>();


    const activate = (id: string) => {
        const confimation: IConfirmation = {
            open: true,
            title: "Confirmation",
            message: "Do you want to activate this user?",
            onSubmit: async () => {
                const result = await DataService.post("api/user/activate/" + id, {});
                if (result.ok) {
                    initilize();
                    toast.success("Success!");
                } else {
                    toast.error("An error occured");
                }
            }
        };

        dispatch(OpenConfirmation(confimation));
    }

    const deactivate = (id: string) => {
        const confimation: IConfirmation = {
            open: true,
            title: "Confirmation",
            message: "Do you want to deactivate this user?",
            onSubmit: async () => {
                const result = await DataService.post("/api/user/deactivate/" + id, {});
                if (result.ok) {
                    initilize();
                    toast.success("Success!");
                } else {
                    toast.error("An error occured");
                }
            }
        };

        dispatch(OpenConfirmation(confimation));
    }


    const headers: EnhanceTableHeaderTypes[] = [
        { id: 'fullName', label: 'Name', type: EnhancedTableColumnType.TEXT, },
        { id: 'email', label: 'Email', type: EnhancedTableColumnType.TEXT },
        { id: 'mobileNumber', label: "Mobile Number", type: EnhancedTableColumnType.PhoneNumber },
        {
            id: 'status', label: 'Status', type: EnhancedTableColumnType.COLORED_CHIP,
            chipColors: {
                'Active': { backgroundColor: 'green', color: "white" },
                'Inactive': { backgroundColor: "red", color: "white" },
                'Pending': { backgroundColor: "orange", color: "white" },
            }
        },
        {
            id: 'actions', label: 'Actions', type: EnhancedTableColumnType.Action,
            actions: [
                { onClick: (id) => { activate(id) }, icon: <CheckIcon fontSize="small" />, label: "Activate User", hidden: (r) => r.status?.toLowerCase() === "active" },
                { onClick: (id) => { deactivate(id) }, icon: <CancelIcon fontSize="small" />, label: "Deactivate User", hidden: (r) => r.status?.toLowerCase() === "inactive" || r.status?.toLowerCase() === "pending" },
            ]
        }
    ];


    const initilize = async () => {
        setLoading(true);
        const response = await DataService.get("/api/User/employees");
        if (response.ok) {
            const list: IUserDetails[] = await response.json();
            const result: Record<string, IUserListing> = {};

            list.forEach(c => {
                result[c.id] = {
                    id: c.id,
                    fullName: c.firstName + " " + c.lastName,
                    email: c.email,
                    country: c.country,
                    mobileNumber: ensurePlusPrefix(c.mobileNumber),
                    status: UserStatus[c.status],
                    role: "--"
                }
            });

            setData(result);
            setLoading(false);
        }
    }

    useEffect(() => {

        initilize();

    }, []);


    const actions: ITableMenuAction[] = [
        // {
        //     key: "addcontact",
        //     title: "Add Contact",
        //     disabled: (selected: string[]) => selected.length > 0,
        //     onClick: () => {
        //         setAddContact(true)
        //     },
        // },
        // {
        //     key: "editcontact",
        //     title: "Edit Contact",
        //     disabled: (selected: string[]) => selected.length === 0,
        //     onClick: (selected) => {

        //         const confirm: IConfirmation = {
        //             open: true,
        //             title: "Hi",
        //             message: "Are you sure?",
        //             onSubmit: () => { console.log("hi") }
        //         }

        //         dispatch(OpenConfirmation(confirm));
        //     }
        // }
    ];

    if (!data || loading) {

        return <Loader size={75} />
    }

    return (
        <div>
            <Helmet>
                <title>User's List</title>
            </Helmet>
            <MainPageTitle title={"Users List"}
                action={{
                    title: "Add User",
                    onClick: () => {
                        navigate("/users/add")
                    },
                }}
            />

            {/* <MainPageTitle title="Customers" /> */}

            <EnhancedTable
                title="Users"
                header={headers}
                data={data}
                actions={actions}
            />

        </div>
    );
};

export default UserListing;

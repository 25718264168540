import { Modal } from '@mui/material';
import { DynamicField, DynamicFieldTypes } from 'src/components/dynamic-widget';
import DynamicFormWidget from 'src/components/dynamic-widget/DynamicFormWidget';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { formatDate } from 'src/helpers/formatting-utils';
import { FileType } from 'src/helpers/file-utils';
import GenericDrawer from 'src/components/drawer/GenericDrawer';
import { GetToday } from 'src/helpers/utils';
interface IBrandListing {
  id: string;
  title: string;
  description: string;
}
interface IUpdateBrandProps {
  onSuccess: () => void;
  onClose: () => void;
  updateData: IBrandListing;
}

const EditBrand: React.FC<IUpdateBrandProps> = ({
  onSuccess,
  onClose,
  updateData
}) => {
  const formConfig: Record<string, DynamicFieldTypes> = {
    title: {
      type: DynamicField.TEXT,
      required: true,
      name: 'title',
      title: 'Title',
      value: updateData.title,
      disabled: false
    },
    description: {
      type: DynamicField.TEXTAREA,
      required: true,
      name: 'description',
      title: 'Description',
      value: updateData.description,
      disabled: false
    },

    file: {
      type: DynamicField.FILE,
      allowedTypes: [FileType.PNG, FileType.JPEG, FileType.JPG],
      required: false,
      name: 'file',
      title: 'Brand File',
      value: '',
      disabled: false
    }
  };

  const submit = async (data: Record<string, any>) => {
    const file: File = data.file;

    let formValue = {
      Title: data.title,
      Description: data.description
      //id: data.id
    };
    const formData = new FormData();
    formData.append('request', JSON.stringify(formValue));

    if (file) {
      formData.append('formFile', file, file.name);
    }

    const req = await DataService.postForm(
      'api/Brand/update/' + updateData.id,
      formData
    );
    if (req.ok) {
      await onSuccess();
      return true;
    } else {
      toast.error('An Error occured!');
    }

    return false;
  };

  return (
    <GenericDrawer title={'Update Brand'} open={true} onClose={onClose}>
      <DynamicFormWidget
        drawerMode
        // title="Add Discount"
        title=""
        fields={formConfig}
        onSubmit={async (e) => {
          return await submit(e);
        }}
      />
    </GenericDrawer>
  );
};

export default EditBrand;

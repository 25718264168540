import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';

import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import { useAppDispatch } from 'src/redux/hooks';
import { cloneObjectBasic } from 'src/helpers/utils';
import { isEmpty, isValidEmail } from 'src/helpers/validation-utils';
import { DataService } from 'src/services/data-service';
import GenericInput from 'src/components/input-components/GenericTextInput';
import GenericDatePicker from 'src/components/input-components/GenericDatePicker';
import GenericSelectInput from 'src/components/input-components/GenericSelectInput';
import GenericDrawer from 'src/components/drawer/GenericDrawer';

const useStyles = makeStyles()((theme) => {
    return {
        // ... (same styles as in LoginPage)
    };
});

interface IEditCustomerProps {
    onClose: () => void;
    onSuccess: () => void;
}

interface IContactFormState {
    values: {
        firstName: string;
        lastName: string;
        dob: string; // date of birth
        gender: string;
        mobileNumber: string;
        email: string;
    };
    errors: {
        firstName: string;
        lastName: string;
        dob: string;
        gender: string;
        mobileNumber: string;
        email: string;
    };
    touched: {
        firstName: boolean;
        lastName: boolean;
        dob: boolean;
        gender: boolean;
        mobileNumber: boolean;
        email: boolean;
    };
}

const getInitialContactFormState = (): IContactFormState => {
    return {
        values: {
            firstName: '',
            lastName: '',
            dob: '',
            gender: '',
            mobileNumber: '',
            email: '',
        },
        errors: {
            firstName: '',
            lastName: '',
            dob: '',
            gender: '',
            mobileNumber: '',
            email: '',
        },
        touched: {
            firstName: false,
            lastName: false,
            dob: false,
            gender: false,
            mobileNumber: false,
            email: false,
        },
    };
};

const EditCustomer: React.FC<IEditCustomerProps> = ({ onClose, onSuccess }) => {
    const { classes } = useStyles();

    const [pageState, setPageState] = useState<IContactFormState>(
        getInitialContactFormState()
    );

    const [submitting, setSubmitting] = useState<boolean>(false);

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const onValueChange = (name: string, value: string) => {
        const newPageState = cloneObjectBasic(pageState);
        (newPageState.values as any)[name] = value;
        (newPageState.errors as any)[name] = validateField(name, value);

        setPageState(newPageState);
    };

    const onBlur = (name: string) => {
        const newPageState = cloneObjectBasic(pageState);
        (newPageState.touched as any)[name] = true;
        (newPageState.errors as any)[name] = validateField(name, (pageState.values as any)[name]);
        setPageState(newPageState);
    };

    const validateField = (name: string, value: string): string => {

        if (name === "firstName" || name === "lastName") {
            if (isEmpty(value)) {
                return "Required";
            }
        }

        if (name === "email") {
            if (isEmpty(value)) {
                return "Required";
            } else if (!isValidEmail(value)) {
                return "Invalid Email!";
            }
        }

        if (name === "mobileNumber") {
            if (isEmpty(value)) {
                return "Required";
            } else if (!isValidEmail(value)) {
                return "Invalid Email!";
            }
        }

        return "";
    };

    const validateForm = () => {
        const newPageState = cloneObjectBasic(pageState);

        newPageState.errors = {
            firstName: validateField("firstName", newPageState.values.firstName),
            lastName: validateField("lastName", newPageState.values.lastName),
            dob: validateField("dob", newPageState.values.dob),
            gender: validateField("gender", newPageState.values.gender),
            mobileNumber: validateField("mobileNumber", newPageState.values.mobileNumber),
            email: validateField("email", newPageState.values.email),
        };

        newPageState.touched = {
            firstName: true,
            lastName: true,
            dob: true,
            gender: true,
            mobileNumber: true,
            email: true,
        };

        setPageState(newPageState);

        return Object.values(newPageState.errors).every(e => isEmpty(e))
    };

    const submit = async () => {
        setSubmitting(true);
        const isValid = validateForm();
        if (isValid) {
            const data = {
                ...pageState.values
            };

            const loginRequest = await DataService.post(
                "api/login",
                data,
                undefined,
                false
            );

            if (loginRequest.ok) {
                // const result: IUserStore = await loginRequest.json();
                // dispatch(LoginUser(result));
                setSubmitting(false);
                onSuccess();
                toast.success("Success");
                // navigate("/");
            } else {
                setSubmitting(false);
            }
        } else {
            setSubmitting(false);
        }
    };

    return (
        <GenericDrawer title={'Add Contact'} open={true} onClose={onClose}>
            <div>
                <GenericInput
                    title="First Name"
                    type="text"
                    value={pageState.values.firstName}
                    onChange={(v) => onValueChange("firstName", v)}
                    name="firstName"
                    error={pageState.touched.firstName ? pageState.errors.firstName : ""}
                    onBlur={onBlur}
                    disabled={submitting}
                />

                <GenericInput
                    title="Last Name"
                    type="text"
                    value={pageState.values.lastName}
                    onChange={(v) => onValueChange("lastName", v)}
                    name="lastName"
                    error={pageState.touched.lastName ? pageState.errors.lastName : ""}
                    onBlur={onBlur}
                    disabled={submitting}
                />
                <GenericInput
                    title="Email"
                    type="text"
                    value={pageState.values.email}
                    onChange={(v) => onValueChange("email", v)}
                    name="email"
                    error={pageState.touched.email ? pageState.errors.email : ""}
                    onBlur={onBlur}
                    disabled={submitting}
                />
                <GenericDatePicker
                    title="Date of Birth"
                    value={pageState.values.dob}
                    onChange={(v) => onValueChange("dob", v)}
                    name="dob"
                    error={pageState.touched.dob ? pageState.errors.dob : ""}
                    onBlur={onBlur}
                    disabled={submitting}
                />
                <GenericSelectInput
                    title="Gender"
                    type="text"
                    value={pageState.values.dob}
                    onChange={(v) => onValueChange("gender", v)}
                    name="gender"
                    error={pageState.touched.gender ? pageState.errors.gender : ""}
                    onBlur={onBlur}
                    disabled={submitting}
                    items={{
                        Male: "Male",
                        Female: "Female"
                    }}
                />

                <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    // className={classes.button}
                    onClick={submit}
                    disabled={submitting}
                >
                    Update
                </Button>
            </div>
        </GenericDrawer>
    );
};

export default EditCustomer;

import React, { useEffect, useState } from 'react';
import EnhancedTable from 'src/components/enhanced-table/EnhancedTable';
import {
  EnhanceTableHeaderTypes,
  EnhancedTableColumnType,
  ITableMenuAction
} from 'src/components/enhanced-table';
import { useAppDispatch } from 'src/redux/hooks';
import { IConfirmation } from 'src/redux/confirmation/types';
import { OpenConfirmation } from 'src/redux/confirmation/confirmationReducer';
import { Helmet } from 'react-helmet-async';
import MainPageTitle from 'src/components/layout-components/main-layout/MainPageTitle';
import { useNavigate, useParams } from 'react-router';
import { DataService } from 'src/services/data-service';
import Loader from 'src/components/Loader';
import { ensurePlusPrefix } from 'src/helpers/utils';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';
import {
  ITableFilterType,
  TableFilterTypes
} from 'src/components/enhanced-table/index-filter';
import AddBrandItem from '../brands-item/addBrand-item';
import EditBrandItem from '../brands-item/editBrand-item';

interface IBrandItemResponseItem {
  id: string;
  brandId: string;
  text: string;
  url: string;
  type: string;
  duration: number;
  active: boolean;
}

interface IBrandItemListing {
  id: string;
  text: string;
  url: string;
  type: string;
  active: boolean;
  status: string;
}

const BrandItemListing: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Record<string, IBrandItemListing>>();
  const [openAddBrandItem, setOpenAddBrandItem] = useState<boolean>(false);
  const [updateData, setUpdateData] = useState<IBrandItemListing>();
  const [openEditBrandItem, setOpenEditBrandItem] = useState<boolean>(false);

  const activate = (id: string) => {
    const confimation: IConfirmation = {
      open: true,
      title: 'Confirmation',
      message: 'Do you want to activate this Brand Item?',
      onSubmit: async () => {
        const result = await DataService.put(
          '/api/BrandItem/activate-item' + '?id=' + id,
          {}
        );
        if (result.ok) {
          initilize();
          toast.success('Success!');
        } else {
          toast.error('An error occured');
        }
      }
    };

    dispatch(OpenConfirmation(confimation));
  };
  const edit = (id: string) => {
    setUpdateData(data[id]);
    setOpenEditBrandItem(true);
  };
  const deactivate = (id: string) => {
    const confimation: IConfirmation = {
      open: true,
      title: 'Confirmation',
      message: 'Do you want to deactivate this Brand Item?',
      onSubmit: async () => {
        const result = await DataService.put(
          '/api/BrandItem/deactivate-item' + '?id=' + id,
          {}
        );
        if (result.ok) {
          initilize();
          toast.success('Success!');
        } else {
          toast.error('An error occured');
        }
      }
    };
    dispatch(OpenConfirmation(confimation));
  };

  const download = async (id: string) => {
    if (data[id].url) {
      window.open(data[id].url, '_blank');
    }
  };

  const headers: EnhanceTableHeaderTypes[] = [
    {
      id: 'text',
      label: 'Text',
      type: EnhancedTableColumnType.TEXT
    },
    {
      id: 'type',
      label: 'Type',
      type: EnhancedTableColumnType.TEXT
    },

    {
      id: 'status',
      label: 'Status',
      type: EnhancedTableColumnType.COLORED_CHIP,
      chipColors: {
        Active: { backgroundColor: 'green', color: 'white' },
        Inactive: { backgroundColor: 'red', color: 'white' }
      }
    },

    {
      id: 'actions',
      label: 'Actions',
      type: EnhancedTableColumnType.Action,
      actions: [
        {
          onClick: (id) => {
            activate(id);
          },
          icon: <CheckIcon fontSize="small" />,
          label: 'Activate',
          hidden: (r) => r.status?.toLowerCase() === 'active'
        },
        {
          onClick: (id) => {
            deactivate(id);
          },
          icon: <CancelIcon fontSize="small" />,
          label: 'Deactivate',
          hidden: (r) => r.status?.toLowerCase() === 'inactive'
        },
        {
          onClick: (id) => {
            edit(id);
          },
          icon: <EditIcon fontSize="small" />,
          label: 'Edit',
          hidden: (r) => false
        },
        {
          onClick: (id) => {
            download(id);
          },
          icon: <CloudDownloadIcon fontSize="small" />,
          label: 'Download',
          hidden: (r) => false
        }
      ]
    }
  ];

  const tableFiler: ITableFilterType[] = [
    {
      type: TableFilterTypes.SELECT,
      name: 'status',
      title: 'Status',
      defaultValue: ['Active']
    }
  ];

  const initilize = async () => {
    setLoading(true);
    const response = await DataService.get(
      '/api/BrandItem/items-by-brand' + '?id=' + id
    );

    if (response.ok) {
      const list: { result: IBrandItemResponseItem[] } = await response.json();
      const result: Record<string, IBrandItemListing> = {};
      list.result.forEach((c) => {
        result[c.id] = {
          id: c.id,
          type: c.type,
          text: c.text,
          url: c.url,
          active: c.active,
          status: c.active ? 'Active' : 'Inactive'
        };
      });
      setData(result);
      setLoading(false);
    }
  };

  useEffect(() => {
    initilize();
  }, []);

  const actions: ITableMenuAction[] = [
    // {
    //     key: "addcontact",
    //     title: "Add Contact",
    //     disabled: (selected: string[]) => selected.length > 0,
    //     onClick: () => {
    //         setAddContact(true)
    //     },
    // },
    // {
    //     key: "editcontact",
    //     title: "Edit Contact",
    //     disabled: (selected: string[]) => selected.length === 0,
    //     onClick: (selected) => {
    //         const confirm: IConfirmation = {
    //             open: true,
    //             title: "Hi",
    //             message: "Are you sure?",
    //             onSubmit: () => { console.log("hi") }
    //         }
    //         dispatch(OpenConfirmation(confirm));
    //     }
    // }
  ];

  if (!data || loading) {
    return <Loader size={75} />;
  }

  return (
    <div>
      <Helmet>
        <title>Brands</title>
      </Helmet>
      <MainPageTitle
        title={'Brand Listing'}
        action={{
          title: 'Add',
          onClick: () => {
            // navigate("/users/add")
            if (!openAddBrandItem) {
              setOpenAddBrandItem(true);
            }
          }
        }}
      />

      {/* <MainPageTitle title="Customers" /> */}

      <EnhancedTable
        title="Brands"
        defaultOrder=""
        header={headers}
        data={data}
        filters={tableFiler}
        actions={actions}
      />

      {openAddBrandItem && (
        <AddBrandItem
          onSuccess={function (): void {
            toast.success('Success!');
            setOpenAddBrandItem(false);
            initilize();
          }}
          onClose={function (): void {
            setOpenAddBrandItem(false);
          }}
        />
      )}

      {openEditBrandItem && (
        <EditBrandItem
          onSuccess={function (): void {
            toast.success('Success!');
            setOpenEditBrandItem(false);
            initilize();
          }}
          onClose={function (): void {
            setOpenEditBrandItem(false);
          }}
          updateData={updateData}
        />
      )}
    </div>
  );
};

export default BrandItemListing;

import { TableBody, TableRow, TableCell, Link, Chip, Checkbox, Tooltip, IconButton, useTheme } from '@mui/material';
import { EnhancedTableColumnType, EnhanceTableHeaderTypes } from '.';
import { formatCurrencyNumber, formatDate, formatDateTime, formatIntPhoneNumber } from '../../helpers/formatting-utils';


interface EnhancedTableBodyProps {
  header: EnhanceTableHeaderTypes[];
  data: Record<string, Record<string, any>>;
  selected: string[];
  handleCheckboxClick: (key: string) => void;
}

const EnhancedTableBody: React.FC<EnhancedTableBodyProps> = ({ header, data, selected, handleCheckboxClick }) => {
  const theme = useTheme();

  const renderCellContent = (value: any, head: EnhanceTableHeaderTypes, rowId: string, row: Record<string, any>) => {
    switch (head.type) {
      case EnhancedTableColumnType.TEXT:
        return value;
      case EnhancedTableColumnType.NUMBER:
        return value;
      case EnhancedTableColumnType.DATE:
        return formatDate(value);
      case EnhancedTableColumnType.DATETIME:
        return formatDateTime(value);
      case EnhancedTableColumnType.COLORED_CHIP:
        return <Chip label={value}
          style={{ cursor: "pointer", backgroundColor: head.chipColors ? head.chipColors[value].backgroundColor : 'white', color: head.chipColors ? head.chipColors[value].color : 'black' }} />;
      case EnhancedTableColumnType.LINK:
        return <Link href={head.url ? head.url(value) : '#'}>{value}</Link>;
      case EnhancedTableColumnType.Clickable:
        return <Link type="button" onClick={() => head.onClick(rowId, row)}>{value}</Link>;
      case EnhancedTableColumnType.CURRENCY:
        return formatCurrencyNumber(value);
      case EnhancedTableColumnType.PhoneNumber:
        return formatIntPhoneNumber(value);
      case EnhancedTableColumnType.Action:
        return head.actions.map(action => action.hidden && action.hidden(row) ? <></> : <Tooltip title={action.label} arrow>
          <IconButton
            sx={{
              '&:hover': {
                background: theme.colors.primary.lighter
              },
              color: theme.palette.primary.main
            }}
            color="inherit"
            size="small"
            onClick={() => action.onClick(rowId)}
          >
            {action.icon}
          </IconButton>
        </Tooltip>)
      default:
        return value;
    }
  };

  return (
    <TableBody>
      {Object.entries(data).map(([key, row]) => (
        <TableRow key={key} tabIndex={-1} hover>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={selected.includes(key)}
              inputProps={{
                'aria-labelledby': `enhanced-table-checkbox-${key}`,
              }}
              onClick={() => handleCheckboxClick(key)}
            />
          </TableCell>
          {header.map((column) => (
            <TableCell key={column.id} align={column.numeric ? 'right' : 'left'}>
              {renderCellContent(row[column.id], column, key, row)}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

export default EnhancedTableBody;

import React from 'react';
import GenericInputWrapper from './GenericInputWrapper';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { makeStyles } from 'tss-react/mui';
import dayjs from 'dayjs';
import { TextField } from '@mui/material';

interface IGenericDatePickerProps {
  title: string;
  value: any;
  onChange: (value: any) => void;
  error?: string;
  name: string;
  onBlur?: (name: string) => void;
  disabled?: boolean;
  minDate?: Date | string;
  maxDate?: Date | string;
}

const useStyles = makeStyles<{ error: boolean }>()((_theme, { error }) => ({
  label: {
    display: 'block'
  },
  input: {
    // width: "100%",
    // border: "1px solid black",
    // borderRadius: "5px",
    // height: "30px",
    // fontSize: "12px",
    // lineHeight: "24px",
    // padding: "0 20px",
    // fontFamily: `"Roboto","Helvetica","Arial",sans-serif`
    width: '100%'
  },
  errorInput: {
    // border: error ? "1px solid red" : undefined
  },
  error: {
    // display: "block",
    // border: error ? "1px solid red" : undefined
  }
}));

const GenericDatePicker: React.FC<IGenericDatePickerProps> = ({
  title,
  value,
  disabled,
  error,
  onChange,
  name,
  minDate,
  maxDate,
  onBlur = () => {}
}) => {
  const { classes, cx } = useStyles({ error: !!error });


  const onValueChange = (v: any) => {
    if(dayjs(v).isValid()){
      onChange(dayjs(v).toISOString())
    }

    return onChange(v);
  }

  return (
    <GenericInputWrapper title="" error={error} name={name}>
      <DatePicker
        // variant="outlined"
        value={value}
        // error={!!error}
        // size="medium"
        label={title}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        onChange={(v) => onValueChange(v)}
        // onBlur={() => onBlur(name)}
        InputProps={{
            error: !!error,
            // variant: "outlined",
            size: "medium",
        }}
        renderInput={(params) => (
          <TextField
            className={cx(
              classes.input,
              !!error ? classes.errorInput : undefined
            )}
            variant="outlined"
            size="medium"
            disabled
            error={!!error}
            {...params}
          />
        )}
      />
    </GenericInputWrapper>
  );
};

export default GenericDatePicker;

export interface ICustomerDetails {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    mobileNumber: string;
    country: string;
    status: CustomerStatus;
}


export enum CustomerStatus {
    Pending,
    Active,
    Inactive,
    Closed
}

export interface ITransaction {
    id: string;
    customerId: string;
    storeId: string;
    total: number;
    pointsGained: number;
    pointsUsed: number;
    createdOn: string;
    extendedData: any;
  }
  


import { Modal } from '@mui/material';
import { DynamicField, DynamicFieldTypes } from 'src/components/dynamic-widget';
import DynamicFormWidget from 'src/components/dynamic-widget/DynamicFormWidget';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FileType } from 'src/helpers/file-utils';
import GenericDrawer from 'src/components/drawer/GenericDrawer';

interface IAddBrandsProps {
  onSuccess: () => void;
  onClose: () => void;
}

const AddOffer: React.FC<IAddBrandsProps> = ({ onSuccess, onClose }) => {
  const formConfig: Record<string, DynamicFieldTypes> = {
    title: {
      type: DynamicField.TEXT,
      required: true,
      name: 'title',
      title: 'Title',
      value: '',
      disabled: false
    },
    description: {
      type: DynamicField.TEXT,
      required: true,
      name: 'description',
      title: 'Description',
      value: '',
      disabled: false
    },
    file: {
      type: DynamicField.FILE,
      allowedTypes: [FileType.PNG, FileType.JPEG, FileType.JPG],
      required: true,
      maxSizeInMbs: 5,
      name: 'file',
      title: 'Brand File',
      value: '',
      disabled: false
    }
  };

  const submit = async (data) => {
    let data2 = {
      Title: data.title,
      Description: data.description
    };

    const formData = new FormData();
    const file: File = data.file;
    formData.append('request', JSON.stringify(data2));
    formData.append('formFile', file, file.name);
    const req = await DataService.postForm('api/Brand/create', formData);

    if (req.ok) {
      await onSuccess();
      return true;
    } else {
      toast.error('An Error occured!');
    }
    return false;
  };

  return (
    <>
      <GenericDrawer title={'Add Brand'} open={true} onClose={onClose}>
        <DynamicFormWidget
          title=""
          drawerMode
          fields={formConfig}
          onSubmit={async (e) => {
            return await submit(e);
          }}
        />
      </GenericDrawer>
    </>
  );
};

export default AddOffer;

import { makeStyles } from 'tss-react/mui';
import { IInformationWidgetField, InformationWidgetFieldTypes } from '.';
import { formatCurrencyNumber, formatDate, formatDateTime } from 'src/helpers/formatting-utils';
import { isEmpty } from 'src/helpers/validation-utils';
import MainPageSection from 'src/components/layout-components/main-layout/MainPageSection';
import { ICustomDropdownOption } from 'src/components/CustomDropdown';


interface IInformationWidgetProps {
    title: string;
    actionsTitle?: string;
    actions?: ICustomDropdownOption[],
    fields: IInformationWidgetField[],
    data: Record<string, any>
}

const useStyles = makeStyles()((theme) => {
    return {
        gridContainer: {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',  // 3 equal columns
            gap: '16px',
            width: "100%"
        },
        colFull: {
            gridColumn: '1 / span 3',  // spanning all 3 columns
        },
        colTwoThirds: {
            gridColumn: '1 / span 2',  // spanning the first 2 columns
        },
        colOneThird: {
            // no gridColumn defined since it's the default behavior
        },
        fieldTitle: {
            display: "block",
            fontWeight: "bold",
            fontSize: "16px",
            lineHeight: "18px",
            margin: "0 0 5px"
        },
        fieldText: {
            display: "block",
            fontSize: "14px",
            lineHeight: "16px"
        }
    };
});

const InformationWidget: React.FC<IInformationWidgetProps> = ({ title, actions = [], fields, data }) => {
    const { classes } = useStyles();


    const renderFieldText = (field: IInformationWidgetField) => {

        if (isEmpty(data[field.name])) {
            return "--";
        }

        switch (field.type) {
            case InformationWidgetFieldTypes.Text:
                return data[field.name];
            case InformationWidgetFieldTypes.Currency:
                return formatCurrencyNumber(data[field.name])
            case InformationWidgetFieldTypes.Date:
                return formatDate(data[field.name])
            case InformationWidgetFieldTypes.Datetime:
                return formatDateTime(data[field.name]);
            case InformationWidgetFieldTypes.Boolean:
                return data[field.name] === true ? "Yes" : "No";
        }


        return data[field.name];


    }

    const renderItem = (field: IInformationWidgetField) => {
        const itemClass = field.width === "full" ? classes.colFull : field.width === "two-third" ? classes.colTwoThirds : undefined;
        return <div className={itemClass} key={field.name}>
            <span className={classes.fieldTitle}>{field.title}</span>

            <span className={classes.fieldText}>{renderFieldText(field)}</span>
        </div>
    }

    return <MainPageSection title={title} actions={actions}>
        <div className={classes.gridContainer}>
            {
                fields.map(f => renderItem(f))
            }
        </div>
    </MainPageSection>
}

export default InformationWidget;
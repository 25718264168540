import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import GenericInput from 'src/components/input-components/GenericTextInput';
import { cloneObjectBasic } from 'src/helpers/utils';
import { isEmpty, isValidEmail, maxCharacters } from 'src/helpers/validation-utils';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'src/redux/hooks';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import GenericButton from 'src/components/GenericButton';
import GenericPhoneInput from 'src/components/input-components/GenericPhoneInput';
import { Card } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import MainPageTitle from 'src/components/layout-components/main-layout/MainPageTitle';
import { isPossiblePhoneNumber } from 'react-phone-number-input';


const useStyles = makeStyles()(
    (_theme,) => ({
        root: {
            padding: "20px 25px",
        },
        leftSection: {
            display: "flex"
        },
        title: {
            display: "block",
            marginRight: "15px"
        },
        searchField: {
            border: 'none',
            outline: 'none',
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
            fontSize: "16px",
            padding: "0 0 5px",
            margin: "0 30px 0 0"
        }
    })
);

interface IAddCustomerProps {
    // onClose: () => void;
    // onSuccess: () => void;
}

interface IContactFormState {
    values: {
        firstName: string;
        lastName: string;
        // dob: string; // date of birth
        // gender: string;
        mobileNumber: string;
        email: string;
    };
    errors: {
        firstName: string;
        lastName: string;
        // dob: string;
        // gender: string;
        mobileNumber: string;
        email: string;
    };
    touched: {
        firstName: boolean;
        lastName: boolean;
        // dob: boolean;
        // gender: boolean;
        mobileNumber: boolean;
        email: boolean;
    };
}

const getInitialContactFormState = (): IContactFormState => {
    return {
        values: {
            firstName: '',
            lastName: '',
            // dob: '',
            // gender: '',
            mobileNumber: '',
            email: '',
        },
        errors: {
            firstName: '',
            lastName: '',
            // dob: '',
            // gender: '',
            mobileNumber: '',
            email: '',
        },
        touched: {
            firstName: false,
            lastName: false,
            // dob: false,
            // gender: false,
            mobileNumber: false,
            email: false,
        },
    };
};

const AddCustomer: React.FC<IAddCustomerProps> = () => {
    const { classes } = useStyles();

    const [pageState, setPageState] = useState<IContactFormState>(
        getInitialContactFormState()
    );

    const [submitting, setSubmitting] = useState<boolean>(false);

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const onValueChange = (name: string, value: string) => {
        const newPageState = cloneObjectBasic(pageState);
        (newPageState.values as any)[name] = value;
        (newPageState.errors as any)[name] = validateField(name, value);

        setPageState(newPageState);
    };

    const onBlur = (name: string) => {
        const newPageState = cloneObjectBasic(pageState);
        (newPageState.touched as any)[name] = true;
        (newPageState.errors as any)[name] = validateField(name, (pageState.values as any)[name]);
        setPageState(newPageState);
    };

    const validateField = (name: string, value: string): string => {

        if (name === "firstName" || name === "lastName") {
            if (isEmpty(value)) {
                return "Required";
            }

            if (!maxCharacters(value, 35)) {
                return "cannot be more than 35 characters";
            }
        }

        if (name === "email") {
            if (isEmpty(value)) {
                // return "Required";
            } else if (!isValidEmail(value)) {
                return "Invalid Email!";
            }

            if (!maxCharacters(value, 50)) {
                return "cannot be more than 35 characters";
            }
        }

        if (name === "mobileNumber") {
            if (isEmpty(value)) {
                return "Required";
            } else if (!isPossiblePhoneNumber("+" + value)) {
                return "Invalid Mobile Number!";
            }
        }

        return "";
    };

    const validateForm = () => {
        const newPageState = cloneObjectBasic(pageState);

        newPageState.errors = {
            firstName: validateField("firstName", newPageState.values.firstName),
            lastName: validateField("lastName", newPageState.values.lastName),
            // dob: validateField("dob", newPageState.values.dob),
            // gender: validateField("gender", newPageState.values.gender),
            mobileNumber: validateField("mobileNumber", newPageState.values.mobileNumber),
            email: validateField("email", newPageState.values.email),
        };

        newPageState.touched = {
            firstName: true,
            lastName: true,
            // dob: true,
            // gender: true,
            mobileNumber: true,
            email: true,
        };

        setPageState(newPageState);

        return Object.values(newPageState.errors).every(e => isEmpty(e))
    };

    const submit = async () => {
        if (!submitting) {
            setSubmitting(true);
            const isValid = validateForm();
            if (isValid) {
                const data = {
                    ...pageState.values,
                    country: "Congo-Kinshasa"
                };
                const loginRequest = await DataService.post(
                    "api/User/register-customer",
                    data,
                    undefined,
                );
                if (loginRequest.ok) {
                    const userId: string = await loginRequest.text();
                    // dispatch(LoginUser(result));
                    setSubmitting(false);
                    // onSuccess();
                    toast.success("Success");
                    navigate("/customers/" + userId);
                    setPageState(getInitialContactFormState());
                } else {
                    const response: { message: string } = await loginRequest.json();
                    toast.error( response.message || "An error occurred!")
                    setSubmitting(false);;
                }
            } else {
                setSubmitting(false);
            }
        }
    };

    return (
        <div>
            <Helmet>
                <title>Add Customer</title>
            </Helmet>
            <MainPageTitle title={"Add Customer"} action={{
                title: "Go to Listing",
                onClick: () => {
                    navigate("/customers")
                },
            }} />

            <Card className={classes.root}>
                <GenericInput
                    title="First Name"
                    type="text"
                    value={pageState.values.firstName}
                    onChange={(v) => onValueChange("firstName", v)}
                    name="firstName"
                    error={pageState.touched.firstName ? pageState.errors.firstName : ""}
                    onBlur={onBlur}
                    disabled={submitting}
                />

                <GenericInput
                    title="Last Name"
                    type="text"
                    value={pageState.values.lastName}
                    onChange={(v) => onValueChange("lastName", v)}
                    name="lastName"
                    error={pageState.touched.lastName ? pageState.errors.lastName : ""}
                    onBlur={onBlur}
                    disabled={submitting}
                />

                <GenericPhoneInput
                    title="Mobile Number"
                    type="text"
                    value={pageState.values.mobileNumber}
                    onChange={(v) => onValueChange("mobileNumber", v)}
                    name="mobileNumber"
                    error={pageState.touched.mobileNumber ? pageState.errors.mobileNumber : ""}
                    onBlur={onBlur}
                    disabled={submitting}
                />
                <GenericInput
                    title="Email"
                    type="text"
                    value={pageState.values.email}
                    onChange={(v) => onValueChange("email", v)}
                    name="email"
                    error={pageState.touched.email ? pageState.errors.email : ""}
                    onBlur={onBlur}
                    disabled={submitting}
                />
                {/* <GenericDatePicker
                    title="Date of Birth"
                    type="text"
                    value={pageState.values.dob}
                    onChange={(v) => onValueChange("dob", v)}
                    name="dob"
                    error={pageState.touched.dob ? pageState.errors.dob : ""}
                    onBlur={onBlur}
                    disabled={submitting}
                />
                <GenericSelectInput
                    title="Gender"
                    type="text"
                    value={pageState.values.dob}
                    onChange={(v) => onValueChange("gender", v)}
                    name="gender"
                    error={pageState.touched.gender ? pageState.errors.gender : ""}
                    onBlur={onBlur}
                    disabled={submitting}
                    items={{
                        Male: "Male",
                        Female: "Female"
                    }}
                /> */}

                <GenericButton
                    type="button"
                    // color="primary"
                    // variant="contained"
                    // className={classes.button}
                    onClick={submit}
                    disabled={submitting}
                    text="Add Contact"
                />
            </Card>

        </div>
        // <GenericDrawer title={'Add Contact'} open={true} onClose={onClose}>

        // </GenericDrawer>
    );
};

export default AddCustomer;

import { Modal } from '@mui/material';
import { DynamicField, DynamicFieldTypes } from 'src/components/dynamic-widget';
import DynamicFormWidget from 'src/components/dynamic-widget/DynamicFormWidget';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { formatDate } from 'src/helpers/formatting-utils';
import { FileType } from 'src/helpers/file-utils';
import GenericDrawer from 'src/components/drawer/GenericDrawer';
import { GetToday } from 'src/helpers/utils';


interface IAddDiscountProps {
  onSuccess: () => void;
  onClose: () => void;
}

const AddDiscount: React.FC<IAddDiscountProps> = ({onSuccess, onClose}) => {

  const navigate = useNavigate();

  const formConfig: Record<string, DynamicFieldTypes> = {
    name: {
      type: DynamicField.TEXT,
      required: true,
      name: 'name',
      title: 'name',
      value: '',
      disabled: false
    },
    startDate: {
      type: DynamicField.DATE,
      required: true,
      name: 'startDate',
      title: 'Start Date',
      min: GetToday(),
      value: '',
      disabled: false
    },
    expiryDate: {
      type: DynamicField.DATE,
      required: true,
      name: 'expiryDate',
      title: 'Expiry Date',
      min: GetToday(),
      value: '',
      disabled: false
    },
    note: {
      type: DynamicField.TEXTAREA,
      required: true,
      name: 'note',
      title: 'note',
      value: '',
      disabled: false
    },
    file: {
      type: DynamicField.FILE,
      allowedTypes: [FileType.XLS, FileType.XLSX],
      required: true,
      name: 'file',
      title: 'Discount File',
      value: '',
      disabled: false
    }
  };


  const submit = async (data: Record<string, any>) => {

    const file: File = data.file;

    let formValue = {
      Name: data.name,
      StartDate: formatDate(data.startDate, "YYYY-MM-DD"),
      ExpiryDate: formatDate(data.expiryDate, "YYYY-MM-DD"),
      Note: data.note
    };
    const formData = new FormData();
    formData.append('request', JSON.stringify(formValue));
    formData.append("formFile", file, file.name);

    const req = await DataService.postForm('/api/Discount/create', formData);
    if(req.ok){
      await onSuccess();
      return true;
    } else {
      toast.error("An Error occured!");
    }

    return false;
  };

  return (
    <GenericDrawer title={'Add Discount'} open={true} onClose={onClose}>
      <DynamicFormWidget
      drawerMode
        // title="Add Discount"
        title=""
        fields={formConfig}
        onSubmit={async (e) => {
          return await submit(e);
        }}
      />
      </GenericDrawer>
  );
};

export default AddDiscount;

export interface IUserDetails {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    mobileNumber: string;
    country: string;
    status: UserStatus;
}

export enum UserStatus {
    Pending,
    Active,
    Inactive,
    Closed
}

export const userRolesList: Record<string, string> = {
    "1": "Employee",
    "2" : "Admin" 
}


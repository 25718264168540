import React, { useEffect, useState } from 'react';
import EnhancedTable from 'src/components/enhanced-table/EnhancedTable';
import { EnhanceTableHeaderTypes, EnhancedTableColumnType, ITableMenuAction } from 'src/components/enhanced-table';
import { useAppDispatch } from 'src/redux/hooks';
import { IConfirmation } from 'src/redux/confirmation/types';
import { OpenConfirmation } from 'src/redux/confirmation/confirmationReducer';
import EditCustomer from './edit-customer';
import { Helmet } from 'react-helmet-async';
import MainPageTitle from 'src/components/layout-components/main-layout/MainPageTitle';
import { useNavigate } from 'react-router';
import { DataService } from 'src/services/data-service';
import { CustomerStatus, ICustomerDetails } from '.';
import Loader from 'src/components/Loader';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import PreviewIcon from '@mui/icons-material/Preview';
import { ensurePlusPrefix } from 'src/helpers/utils';
import { TableFilterTypes } from 'src/components/enhanced-table/index-filter';


interface ICustomerListing {
    id: string;
    fullName: string;
    mobileNumber: string;
    email: string;
    status: string;
    country: string;
}



const CustomerListing: React.FC = () => {

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [editContact, setEditContact] = useState<boolean>(false);
    const [data, setData] = useState<Record<string, ICustomerListing>>();


    const headers: EnhanceTableHeaderTypes[] = [
        { id: 'fullName', label: 'Full Name', type: EnhancedTableColumnType.Clickable, onClick: (id, row) => { navigate("/customers/" + id) } },
        { id: 'email', label: 'Email', type: EnhancedTableColumnType.TEXT },
        { id: 'mobileNumber', label: "Mobile Number", type: EnhancedTableColumnType.TEXT },
        {
            id: 'status', label: 'Status', type: EnhancedTableColumnType.COLORED_CHIP,
            chipColors: {
                'Active': { backgroundColor: 'green', color: "white" },
                'Inactive': { backgroundColor: "red", color: "white" },
                'Pending': { backgroundColor: "orange", color: "white" },
            }
        },
    ];


    const initilize = async () => {
        const response = await DataService.get("/api/User/customers");
        if (response.ok) {
            const list: ICustomerDetails[] = await response.json();
            const result: Record<string, ICustomerListing> = {};

            list.forEach(c => {
                result[c.id] = {
                    id: c.id,
                    fullName: c.firstName + " " + c.lastName,
                    email: c.email,
                    country: c.country,
                    mobileNumber: ensurePlusPrefix(c.mobileNumber),
                    status: CustomerStatus[c.status]
                }
            });

            setData(result);
        }
    }

    useEffect(() => {

        initilize();

    }, []);


    const actions: ITableMenuAction[] = [
        // {
        //     key: "addcontact",
        //     title: "Add Contact",
        //     disabled: (selected: string[]) => selected.length > 0,
        //     onClick: () => {
        //         setAddContact(true)
        //     },
        // },
        {
            key: "editcontact",
            title: "Edit Contact",
            disabled: (selected: string[]) => selected.length === 0,
            onClick: (selected) => {

                const confirm: IConfirmation = {
                    open: true,
                    title: "Hi",
                    message: "Are you sure?",
                    onSubmit: () => { console.log("hi") }
                }
                dispatch(OpenConfirmation(confirm));
            }
        }
    ];

    if (!data) {

        return <Loader size={75} />
    }

    return (
        <div>
            <Helmet>
                <title>Customer's List</title>
            </Helmet>
            <MainPageTitle title={"Customers List"} action={{
                title: "Add Customer",
                onClick: () => {
                    navigate("/customers/add")
                },
            }} />

            {/* <MainPageTitle title="Customers" /> */}

            <EnhancedTable
                title="Customers"
                header={headers}
                data={data}
                actions={actions}
                filters={[
                    // {
                    //     type: TableFilterTypes.SELECT,
                    //     name: "firstName",
                    //     title: "First Name",
                    // },
                    // {
                    //     type: TableFilterTypes.SELECT,
                    //     name: "lastName",
                    //     title: "Last Name"
                    // }, 
                    {
                        type: TableFilterTypes.SELECT,
                        name: "status",
                        title: "Status"
                    }
                ]}  
            />

            {/* {
                addContact && <AddCustomer onClose={() => {
                    setAddContact(false)
                }} onSuccess={function (): void {
                    throw new Error('Function not implemented.');
                }} />
            } */}

            {
                editContact && <EditCustomer onClose={() => {
                    setEditContact(false)
                }} onSuccess={function (): void {
                    throw new Error('Function not implemented.');
                }} />
            }

        </div>
    );
};

export default CustomerListing;

import * as React from 'react';
import { Toolbar, Typography, Paper, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Dropdown, { ICustomDropdownOption } from '../CustomDropdown';
import { ITableMenuAction } from '.';
import GenericInput from '../input-components/GenericTextInput';
import { ITableFilterType } from './index-filter';

interface EnhancedTableToolbarProps {
  // title: string;
  searchKey: string;
  selected: string[];
  actions?: ITableMenuAction[];
  onSearchUpdate: (v: string) => void;
  filters?: ITableFilterType[];
}

const useStyles = makeStyles()(
  (_theme,) => ({
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      alignContent: "center",
      alignItems: "center",
      width: "100%",
      minHeight: "65px",
      flexWrap: "wrap"
    },
    leftSection: {
      display: "flex"
    },
    title: {
      display: "block",
      marginRight: "15px"
    },
    searchField: {
      border: 'none',
      outline: 'none',
      // borderBottom: '1px solid rgba(224, 224, 224, 1)',
      fontSize: "16px",
      padding: "0 0 5px",
      margin: "0 0 0 0"
    },
    searchFieldWrapper: {
      margin: "0 15px 0 0"
    }
  })
);

const EnhancedTableToolbar: React.FC<EnhancedTableToolbarProps> = ({ searchKey, selected, actions = [], onSearchUpdate }) => {

  const { classes, cx } = useStyles();


  const dropdownOption: ICustomDropdownOption[] = actions.map((action) => {
    return {
      key: action.key,
      title: action.title,
      onClick: () => { action.onClick(selected) },
      disabled: action.disabled(selected)
    }
  });


  return (
    <Toolbar className={classes.toolbar}>
      <div className={classes.leftSection}>
        {/* <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {title}
        </Typography> */}
        <GenericInput containerClassName={classes.searchFieldWrapper} hideError={true} title="" name="" size="small" value={searchKey} type='text' placeholder='Search...' className={classes.searchField} onChange={(v) => {onSearchUpdate(v)}} />
      </div>

      {
        actions && actions.length > 0 ? <Dropdown
          title="Actions"
          options={dropdownOption}
        /> : <span />
      }

    </Toolbar>
  );
};

export default EnhancedTableToolbar;

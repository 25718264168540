import React, { useEffect, useState } from 'react';
// import MainLayout from "./layouts/main-layout/MainLayout";
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
// import LoginPage from "./auth-pages/Login";
// import { Homepage } from "./pages/home";
// import SamplePage from "./pages/SamplePage";
// import Test from "./test/Test";
// import CustomerListing from "./customer/customer-listing";
import { useAppSelector } from './redux/hooks';
import SidebarLayout from './layouts/SidebarLayout';
import CustomerListing from './pages/customers/customer-listing';
import CustomerPage from './pages/customers/CustomerPage';
import Transactions from './content/applications/Transactions';
import AddCustomer from './pages/customers/add-customer';
import TransactionListing from './pages/transactions/transaction-listing';
import UserListing from './pages/users/user-listing';
import AddUser from './pages/users/add-user';
import Overview from './pages/overview/index';
import DiscountListing from './pages/discounts/discount-listing';
import OfferListing from './pages/offers/offer-listing';
import NotificationListing from './pages/notifications/notification-listing';
import AddOffer from './pages/offers/add-offer';
import AddDiscount from './pages/discounts/add-discount';
import BrandListing from './pages/brands/brands-listing';
import BrandItemListing from './pages/brands-item/brandItem-listing';
// import CustomerPage from "./customer/CustomerPage";
// import TransactionListing from "./transactions/transaction-listing";
// import SamplePage from "./pages/SamplePage";

export const Protected: React.FC = () => {
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector((s) => s.user.isAuthenticated);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login', { replace: true });
    }
  }, [isAuthenticated]);

  if (isAuthenticated) {
    return (
      <Routes>
        <Route path="" element={<SidebarLayout />}>
          {/* <Route path="/dashboard" element={<Homepage />} />
          <Route path="/test" element={<Test />} />
          <Route path="/dashboard2" element={<h1>Dashboard 2</h1>} />
          <Route path="/customers" element={<CustomerListing />} />
          <Route path="/customers/:id" element={<CustomerPage />} />
          <Route path="/transactions" element={<TransactionListing />} />
          <Route path="/transactions/:id" element={<TransactionListing />} />
          <Route path="/customers" element={<CustomerListing />} />
          <Route path="/customers/:id" element={<CustomerPage />} />

          <Route path="*" element={<SamplePage />} /> */}
          <Route path="/overview" element={<Overview />} />
          <Route path="/customers" element={<CustomerListing />} />
          <Route path="/customers/add" element={<AddCustomer />} />

          <Route path="/customers/:id" element={<CustomerPage />} />

          <Route path="/customers/add" element={<CustomerPage />} />

          <Route path="/transactions" element={<TransactionListing />} />
          <Route path="/users" element={<UserListing />} />
          <Route path="/users/add" element={<AddUser />} />

          <Route path="/discounts" element={<DiscountListing />} />
          {/* <Route path="/discounts/add" element={<AddDiscount />} /> */}

          <Route path="/offers" element={<OfferListing />} />
          <Route path="/notifications" element={<NotificationListing />} />
          {/* <Route path="/teirs" element={<OfferListing />} /> */}
          <Route path="/brands" element={<BrandListing />} />
          <Route path="/brands/:id" element={<BrandItemListing />} />

          {/* <Route path="/offers/add" element={<AddOffer />} />*/}

          <Route path="*" element={<div />} />
        </Route>
      </Routes>
    );
  }

  return <div />;
};

import { Modal } from '@mui/material';
import { DynamicField, DynamicFieldTypes } from 'src/components/dynamic-widget';
import DynamicFormWidget from 'src/components/dynamic-widget/DynamicFormWidget';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { FileType } from 'src/helpers/file-utils';
import GenericDrawer from 'src/components/drawer/GenericDrawer';

interface IAddBrandsProps {
  onSuccess: () => void;
  onClose: () => void;
}
const items = { video: 'Video', text: 'Text', image: 'Image', link: 'Link' };

const AddBrandItem: React.FC<IAddBrandsProps> = ({ onSuccess, onClose }) => {
  const { id } = useParams();

  const formConfig: Record<string, DynamicFieldTypes> = {
    text: {
      type: DynamicField.TEXT,
      required: true,
      name: 'text',
      title: 'Text',
      value: '',
      disabled: false
    },
    type: {
      type: DynamicField.SELECT,
      required: true,
      name: 'type',
      title: 'Type',
      items: items,
      value: '',
      disabled: false
    },
    file: {
      type: DynamicField.FILE,
      allowedTypes: [FileType.PNG, FileType.JPEG, FileType.JPG, FileType.MP4],
      required: true,
      maxSizeInMbs: 10,
      name: 'file',
      title: 'Brand Item File',
      value: '',
      disabled: false
    }
  };

  const submit = async (data) => {
    let data2 = {
      text: data.text,
      type: data.type,
      id: data.id,
      BrandId: id
    };

    const formData = new FormData();
    const file: File = data.file;
    formData.append('request', JSON.stringify(data2));
    formData.append('formFile', file, file.name);
    const req = await DataService.postForm(
      '/api/BrandItem/create-item',
      formData
    );

    if (req.ok) {
      await onSuccess();
      return true;
    } else {
      toast.error('An Error occured!');
    }
    return false;
  };

  return (
    <>
      <GenericDrawer title={'Add Brand Item'} open={true} onClose={onClose}>
        <DynamicFormWidget
          title=""
          drawerMode
          fields={formConfig}
          onSubmit={async (e) => {
            return await submit(e);
          }}
        />
      </GenericDrawer>
    </>
  );
};

export default AddBrandItem;

import { Button } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

interface GenericButtonProps {
    text: string;
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    className?: string;
    onClick?: () => void;
    variant?: "text" | "contained" | "outlined";
    color?: "primary" | "inherit" | "secondary" | "success" | "error" | "info" | "warning";
}

const useStyles = makeStyles()(() => {
    return {
        button: {
            // display: "block",
            // padding: '10px 20px',
            // border: 'none',
            // borderRadius: '4px',
            // backgroundColor: '#243043',
            // color: 'white',
            // fontSize: '16px',
            // cursor: 'pointer',
            // margin: '0 auto',
            // '&:hover': {
            //     backgroundColor: '#0056b3',
            // },
            // '&:disabled': {
            //     backgroundColor: '#cccccc',
            //     cursor: 'not-allowed',
            // },
            width: "175px"
        },
    };
});


const GenericButton: React.FC<GenericButtonProps> = ({
    text,
    type = 'button',
    disabled = false,
    className = '',
    onClick,
    variant="contained",
    color='primary'
}) => {
    const { classes } = useStyles();

    return (
        <Button
            type={type}
            disabled={disabled}
            variant={variant}
            color={color}
            className={`${classes.button} ${className}`}
            onClick={onClick}
            
        >
            {text}
        </Button>
    );
};

export default GenericButton;


import { Modal } from '@mui/material';
import { DynamicField, DynamicFieldTypes } from 'src/components/dynamic-widget';
import DynamicFormWidget from 'src/components/dynamic-widget/DynamicFormWidget';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { formatDate } from 'src/helpers/formatting-utils';
import { FileType } from 'src/helpers/file-utils';
import GenericDrawer from 'src/components/drawer/GenericDrawer';
import { GetToday } from 'src/helpers/utils';
interface IDiscountListing {
  id: string;
  name: string;
  note: string;
  startDate: string;
  expiryDate: string;
  status: string;
}
interface IUpdateDiscountProps {
  onSuccess: () => void;
  onClose: () => void;
  updateData: IDiscountListing;
}

const EditDiscount: React.FC<IUpdateDiscountProps> = ({
  onSuccess,
  onClose,
  updateData
}) => {
  const navigate = useNavigate();

  const formConfig: Record<string, DynamicFieldTypes> = {
    name: {
      type: DynamicField.TEXT,
      required: true,
      name: 'name',
      title: 'name',
      value: updateData.name,
      disabled: false
    },
    startDate: {
      type: DynamicField.DATE,
      required: true,
      name: 'startDate',
      title: 'Start Date',
      min: GetToday(),
      value: updateData.startDate,
      disabled: false
    },
    expiryDate: {
      type: DynamicField.DATE,
      required: true,
      min: GetToday(),
      name: 'expiryDate',
      title: 'Expiry Date',
      value: updateData.expiryDate,
      disabled: false
    },
    note: {
      type: DynamicField.TEXTAREA,
      required: true,
      name: 'note',
      title: 'note',
      value: updateData.note,
      disabled: false
    },
    file: {
      type: DynamicField.FILE,
      allowedTypes: [FileType.XLS, FileType.XLSX],
      required: false,
      name: 'file',
      title: 'Discount File',
      value: '',
      disabled: false
    }
  };

  const submit = async (data: Record<string, any>) => {
    const file: File = data.file;

    let formValue = {
      Name: data.name,
      StartDate: formatDate(data.startDate, 'YYYY-MM-DD'),
      ExpiryDate: formatDate(data.expiryDate, 'YYYY-MM-DD'),
      Note: data.note
    };
    const formData = new FormData();
    formData.append('request', JSON.stringify(formValue));
    if (file) formData.append('formFile', file, file.name);

    const req = await DataService.postForm(
      '/api/Discount/update/' + updateData.id,
      formData
    );
    if (req.ok) {
      await onSuccess();
      return true;
    } else {
      toast.error('An Error occured!');
    }

    return false;
  };

  return (
    <GenericDrawer title={'Update Discount'} open={true} onClose={onClose}>
      <DynamicFormWidget
        drawerMode
        // title="Add Discount"
        title=""
        fields={formConfig}
        onSubmit={async (e) => {
          return await submit(e);
        }}
      />
    </GenericDrawer>
  );
};

export default EditDiscount;

import { Button, Grid, Typography } from '@mui/material';
import { AddTwoTone } from '@mui/icons-material';
import PageTitleWrapper from 'src/components/PageTitleWrapper';


interface IMainPageTitleProps {
    title: string;
    subtitle?: string;
    // actionsTitle?: string;
    // actions?: ICustomDropdownOption[]
    action?: {
        title: string;
        onClick: () => void;
        disabled?: boolean;
    }
}



const MainPageTitle: React.FC<IMainPageTitleProps> = ({ title, subtitle, action }) => {

    return (
        <PageTitleWrapper>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h3" component="h3" gutterBottom>
                        {title}
                    </Typography>
                    {
                        !!subtitle &&
                        <Typography variant="subtitle2">
                            {subtitle}
                        </Typography>
                    }

                </Grid>
                <Grid item>
                    {
                        action ?
                            <Button
                                sx={{ mt: { xs: 2, md: 0 } }}
                                variant="contained"
                                onClick={action.onClick}
                                disabled={action.disabled}
                                startIcon={<AddTwoTone fontSize="small" />}
                            >
                                {action.title}
                            </Button> : <></>
                    }
                </Grid>
            </Grid>
        </PageTitleWrapper>

    );
}





export default MainPageTitle;